import _ from "lodash";
import { formatNumber, sumArray } from "../../../components/utils/functions";
import moment from "moment";

export default function DailyGlobalReportCard(props) {
  const { ca, report } = props;
  const getTodayObj = (objs) => {
    // console.log(objs)
    if (objs) {
      const item = objs?.filter((obj) => {
        if (moment(obj.date).isSame(moment(), "day")) {
          console.log("Valid date");
          return obj;
        }
      });
      return item[0];
    }
  };
  return (
    <div className="col-md-5">
      <div className="bg-light p-3 mb-3">
        <p className="text-uppercase mb-0 text-center">Aujourd'hui</p>
      </div>
      <div className="border rounded-11 p-2">
        <div className="border rounded-11 border-primary">
          <table className="table text-uppercase text-left">
            <thead className="bg-light">
              <tr>
                <th className="text-center">Libelles</th>
                <th>Valeur</th>
                <th style={{ width: "30px" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr className="badge-soft-success d-none ">
                <td>SOLDE CAISSE INITIAL</td>
                <td>
                  <span>
                    {/* {getTodayObj(report?.stats)} */}
                    {formatNumber(
                      sumArray(
                        getTodayObj(report?.stats)?.caisses?.map(
                          (i) => (i.is_main && i.is_admin === false) ? i.initial_sold : 0
                        ) ?? []
                      )
                    )}
                  </span>
                </td>
                <td className="text-success text-center">+</td>
              </tr>
              <tr className="badge-soft-success ">
                <td>Chiffre d'affaires sur les Prestations</td>
                <td>
                  <span>
                    {/* {getTodayObj(report?.stats)} */}
                    {formatNumber(
                      sumArray(
                        getTodayObj(report?.stats)?.report?.map(
                          (i) => i.amount_services
                        ) ?? []
                      )
                    )}
                  </span>
                </td>
                <td className="text-success text-center">+</td>
              </tr>
              {/* Details des prestations */}
              {/* Coiffure */}
              {getTodayObj(report?.stats)?.report?.map((i, index) =>
                i?.category?.type === "SERVICE" &&
                String(i?.category?.name)?.toUpperCase() === "COIFFURES" ? (
                  <tr className=" small" key={"prsta-" + index}>
                    <td>
                      <span className="d-flex">
                        <span>- {i?.category?.name}</span>
                        <span className="ms-auto border border-primary px-2">
                          {formatNumber(i?.amount_services)}
                        </span>
                      </span>
                    </td>
                    <td></td>
                    <td className="text-success text-center"></td>
                  </tr>
                ) : null
              )}
              {/* Autres services */}
              {getTodayObj(report?.stats)?.report?.map((i, index) =>
                i?.category?.type === "SERVICE" &&
                String(i?.category?.name)?.toUpperCase() != "COIFFURES" ? (
                  <tr className=" small" key={"prsta-" + index}>
                    <td>
                      <span className="d-flex">
                        <span>- {i?.category?.name}</span>
                        <span className="ms-auto border border-primary px-2">
                          {formatNumber(i?.amount_services)}
                        </span>
                      </span>
                    </td>
                    <td></td>
                    <td className="text-success text-center"></td>
                  </tr>
                ) : null
              )}
              <tr className="badge-soft-success ">
                <td>Chiffre d'affaires sur les Produits</td>
                <td>
                  <span>
                    {formatNumber(
                      sumArray(
                        getTodayObj(report?.stats)?.report?.map(
                          (i) => i.amount_products
                        ) ?? []
                      )
                    )}
                  </span>
                </td>
                <td className="text-success text-center">+</td>
              </tr>
              <tr className=" bg-primary-2">
                <td className="text-center">Chiffre d'affaires - Total Nº1</td>
                <td className="">
                  {formatNumber(
                    sumArray(
                      getTodayObj(report?.stats)?.report?.map(
                        (i) => i.amount_products
                      ) ?? []
                    ) +
                      sumArray(
                        getTodayObj(report?.stats)?.report?.map(
                          (i) => i.amount_services
                        )
                      ) ?? []
                  )}
                </td>
                <td className="text-success text-center"></td>
              </tr>
              <tr className="badge-soft-success ">
                <td>TOTAL DÉPÔT EN COMPTE JOUR</td>
                <td>
                  {formatNumber(getTodayObj(report?.stats)?.total_depots_jour)}
                </td>
                <td className="text-success text-center">+</td>
              </tr>
              <tr className=" bg-primary-2">
                <td className="text-center">Chiffre d'affaires - Total Nº2</td>
                <td className="">
                  {formatNumber(
                    sumArray(
                      getTodayObj(report?.stats)?.report?.map(
                        (i) => i.amount_products
                      )
                    ) +
                      sumArray(
                        getTodayObj(report?.stats)?.report?.map(
                          (i) => i.amount_services
                        )
                      ) +
                      getTodayObj(report?.stats)?.total_depots_jour
                  )}
                </td>
                <td className="text-success text-center"></td>
              </tr>
              <tr className="badge-soft-danger ">
                <td className="">Dépenses Globales</td>
                <td>
                  {formatNumber(
                    sumArray(
                      _.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                        is_admin: false,
                        is_main: true,
                        mobile: null,
                      })[0]?.transactions.map((i) =>
                        i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0
                      )
                    ) +
                      sumArray(
                        _.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                          is_admin: true,
                          is_main: false,
                        })[0]?.transactions.map((i) =>
                          i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0
                        )
                      )
                  )}
                </td>
                <td className="text-danger text-center">-</td>
              </tr>
              <tr className="small">
                <td>
                  <span className="d-flex">
                    <span>- Caisse principale</span>
                    <span className="ms-auto border border-primary px-2">
                      {formatNumber(
                        sumArray(
                          _.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                            is_admin: false,
                            is_main: true,
                            mobile: null,
                          })[0]?.transactions.map((i) =>
                            i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0
                          )
                        )
                      )}
                    </span>
                  </span>
                </td>
                <td></td>
                <td className="text-danger text-center"></td>
              </tr>
              <tr className="small">
                <td>
                  <span className="d-flex">
                    <span>- Coffre</span>
                    <span className="ms-auto border border-primary px-2">
                      {formatNumber(
                        sumArray(
                          _.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                            is_admin: true,
                            is_main: false,
                            mobile: null,
                          })[0]?.transactions.map((i) =>
                            i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0
                          )
                        )
                      )}
                    </span>
                  </span>
                </td>
                <td></td>
                <td className="text-danger text-center"></td>
              </tr>
              <tr className="  bg-primary-2">
                <td className="text-center">MARGE JOURNALIÈRE</td>
                <td>
                  {formatNumber(
                    sumArray(
                      getTodayObj(report?.stats)?.report?.map(
                        (i) => i.amount_products
                      )
                    ) +
                      sumArray(
                        getTodayObj(report?.stats)?.report?.map(
                          (i) => i.amount_services
                        )
                      ) +
                      sumArray(
                        _.filter(
                          getTodayObj(report?.stats)?.transactions ?? [],
                          {
                            reason: "APPROVISIONNEMENT_DE_COMPTE",
                          }
                        )?.map((i) => i.amount)
                      ) -
                      sumArray(
                        _.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                          is_admin: true,
                          is_main: false,
                        })[0]?.transactions.map((i) =>
                          i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0
                        )
                      ) -
                      sumArray(
                        _.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                          is_admin: false,
                          is_main: true,
                        })[0]?.transactions.map((i) =>
                          i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0
                        )
                      ) -
                      sumArray(
                        _.filter(
                          getTodayObj(report?.stats)?.payment_methods ?? [],
                          {
                            payment_method: "SOLDE_DISPONIBLE",
                          }
                        )[0]?.data.map((i) => parseFloat(i?.amount))
                      ) -
                      sumArray(
                        _.filter(
                          getTodayObj(report?.stats)?.payment_methods ?? [],
                          {
                            payment_method: "CREDIT",
                          }
                        )[0]?.data.map((i) => parseFloat(i?.amount))
                      ) -
                      sumArray(
                        _.filter(
                          getTodayObj(report?.stats)?.payment_methods ?? [],
                          {
                            payment_method: "OFFER",
                          }
                        )[0]?.data.map((i) => parseFloat(i?.amount))
                      ) -
                      sumArray(
                        _.filter(
                          getTodayObj(report?.stats)?.transactions ?? [],
                          {
                            reason: "SALAIRES",
                          }
                        )?.map((i) => i.amount)
                      )
                  )}
                </td>
                <td className="text-success text-center"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
