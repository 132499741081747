import moment from 'moment';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import _ from 'lodash';
import { URLS } from '../../services/urls';
import { useSelector } from 'react-redux';
import { aggregateAgenciesWallets, compareDates, formatNumber, sumArray } from '../../components/utils/functions';
import { DatePicker } from 'react-responsive-calendar-picker';
import { useReactToPrint } from 'react-to-print';
import LoaderPage from '../../components/LoaderPage';
import PdfHeader from '../../components/Pdf/PdfHeader';
import PageTitle from '../../components/PageTitle';
import DailyReportCard from './components/DailyReportCard';
import DailyGlobalReportCard from './components/DailyGlobalReportCard';

const formatDate = (date) => {
  if (date) {
      // return String(date.toLocaleDateString()).split('/').reverse().join('-');
      return moment(date).format('YYYY-MM-DD');
  } else {
    return '9999-01-01';
  }
};

const GlobalReport = () => {
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const { active_agency, agencies } = useSelector((state) => state.agencies);

  const reportRef = useRef();

  const {
    data: report,
    isLoading: loadingCa,
    mutate: mutateCa,
  } = useSWR(
    URLS.STATS.REPPORT.global(
      formatDate(dates.checkin),
      formatDate(dates.checkout),
      active_agency?.id !== "" ? active_agency?.id : agencies[0]?.id
    )
  );

  const getTodayObj = (objs) => {
    console.log(objs);
    if (objs) {
      const item = objs?.filter((obj) => {
        if (moment(obj.date).isSame(moment(), 'day')) {
          console.log('Valid date');
          return obj;
        }
      });
      return item[0];
    }
  };

  const handlePrint = useReactToPrint({
    content: () => reportRef?.current,
    documentTitle: `Rapport du ${dates.checkin && dates.checkin.toLocaleDateString()} {' '}
    ${dates.checkout && 'au'}{' '}
    ${dates.checkout && dates.checkout.toLocaleDateString()}`,
    // onBeforeGetContent: () => setHideButton(true),
    // onAfterPrint: () => setHideButton(true)
  });
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <PageTitle
            title={'Rapport d\'activité Global'}
            breadcrumbs={
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Rapport d'activité Global
                </li>
              </ol>
            }
          />
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="">
            <h3 className="text-uppercas">Rapport d'activité Global</h3>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div>
                  <div className="py-3 position-relative" style={{ zIndex: '1' }}>
                    <DatePicker
                      dates={dates}
                      setDates={setDates}
                      open={open}
                      setOpen={setOpen}
                      normal={true}
                    >
                      <button className="btn btn-primary rounded-0" onClick={() => setOpen(!open)}>
                        Rapport du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                        {dates.checkout && 'au'}{' '}
                        {dates.checkout && dates.checkout.toLocaleDateString()}
                      </button>
                    </DatePicker>
                  </div>
                </div>
                <div className="ms-auto my-auto">
                  <div className="d-flex">
                    <Link
                      to={'/repports/global/calendar'}
                      className="btn me-2 btn-primary rounded-0 text-uppercase"
                    >
                      Voir le calendrier
                    </Link>
                    <button
                      className="btn btn-primary rounded-0 text-uppercase"
                      onClick={handlePrint}
                    >
                      Imprimer
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {loadingCa && (
              <div className="py-3">
                <LoaderPage label="Generation du rapport en cours..." />
              </div>
            )}
            {!loadingCa && (
              <div className="col-md-12" ref={reportRef}>
                <div className="border p-3 rapport-activity">
                  <div className="my-3 text-center">
                    {/* <PdfHeader /> */}
                  </div>

                  <div className="repport-page-content"> 
                    <div className="row op">
                      <div className="col-md-1"></div>
  
                        <DailyGlobalReportCard report={report} />
                      <div className="col-md-5 d-none">
                        <div className="bg-light p-3 mb-3">
                          <p className="text-uppercase mb-0 text-center">Aujourd'hui</p>
                        </div>
                        <div className="border rounded-11 p-2">
                          <div className="border rounded-11 border-primary">
                            <table className="table text-uppercase text-left">
                              <thead className="bg-light">
                                <tr>
                                  <th className="text-center">Libelles</th>
                                  <th>Valeur</th>
                                  <th style={{ width: '30px' }}>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="badge-soft-success ">
                                  <td>Chiffre d'affaires sur les Prestations</td>
                                  <td>
                                    <span>
                                      {/* {getTodayObj(report?.stats)} */}
                                      {formatNumber(
                                        sumArray(
                                          getTodayObj(report?.stats)?.report?.map(
                                            (i) => i.amount_services
                                          )
                                        )
                                      )}
                                    </span>
                                  </td>
                                  <td className="text-success text-center">+</td>
                                </tr>
                                {/* Details des prestations */}
                                {/* Coiffure */}
                                {getTodayObj(report?.stats)?.report?.map((i, index) =>
                                  i?.category?.type === 'SERVICE' &&
                                  String(i?.category?.name)?.toUpperCase() === 'COIFFURES' ? (
                                    <tr className=" small" key={'prsta-' + index}>
                                      <td>
                                        <span className="d-flex">
                                          <span>- {i?.category?.name}</span>
                                          <span className="ms-auto border border-primary px-2">
                                            {formatNumber(i?.amount_services)}
                                          </span>
                                        </span>
                                      </td>
                                      <td></td>
                                      <td className="text-success text-center"></td>
                                    </tr>
                                  ) : null
                                )}
                                {/* Autrres services */}
                                {getTodayObj(report?.stats)?.report?.map((i, index) =>
                                  i?.category?.type === 'SERVICE' &&
                                  String(i?.category?.name)?.toUpperCase() != 'COIFFURES' ? (
                                    <tr className=" small" key={'prsta-' + index}>
                                      <td>
                                        <span className="d-flex">
                                          <span>- {i?.category?.name}</span>
                                          <span className="ms-auto border border-primary px-2">
                                            {formatNumber(i?.amount_services)}
                                          </span>
                                        </span>
                                      </td>
                                      <td></td>
                                      <td className="text-success text-center"></td>
                                    </tr>
                                  ) : null
                                )}
                                <tr className="badge-soft-success ">
                                  <td>Chiffre d'affaires sur les Produits</td>
                                  <td>
                                    <span>
                                      {formatNumber(
                                        sumArray(
                                          getTodayObj(report?.stats)?.report?.map(
                                            (i) => i.amount_products
                                          )
                                        )
                                      )}
                                    </span>
                                  </td>
                                  <td className="text-success text-center">+</td>
                                </tr>
                                <tr className=" bg-primary-2">
                                  <td className="text-center">Chiffre d'affaires - Total Nº1</td>
                                  <td className="">
                                    {formatNumber(
                                      sumArray(
                                        getTodayObj(report?.stats)?.report?.map(
                                          (i) => i.amount_products
                                        )
                                      ) +
                                        sumArray(
                                          getTodayObj(report?.stats)?.report?.map(
                                            (i) => i.amount_services
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-success text-center">+</td>
                                </tr>
                                <tr className="badge-soft-success ">
                                  <td>Total des soldes en compte</td>
                                  <td>
                                  {formatNumber(
                                          getTodayObj(report?.stats)?.soldes_clients
                                        )}
                                  </td>
                                  <td className="text-success text-center">+</td>
                                </tr>
                                <tr className=" bg-primary-2">
                                  <td className="text-center">Chiffre d'affaires - Total Nº2</td>
                                  <td className="">
                                    {formatNumber(
                                      sumArray(
                                        getTodayObj(report?.stats)?.report?.map(
                                          (i) => i.amount_products
                                        )
                                      ) +
                                        sumArray(
                                          getTodayObj(report?.stats)?.report?.map(
                                            (i) => i.amount_services
                                          )
                                        ) +
                                        sumArray(
                                          _.filter(getTodayObj(report?.stats)?.transactions ?? [], {
                                            reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                          })?.map((i) => i.amount)
                                        )
                                    )}
                                  </td>
                                  <td className="text-success text-center">+</td>
                                </tr>
                                <tr className="badge-soft-danger ">
                                  <td className="">Dépenses Globales</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        _.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                                          is_admin: false,
                                          is_main: true,
                                          mobile: null,
                                        })[0]?.transactions.map((i) =>
                                          i?.type === 'WITHDRAWAL' ? parseFloat(i?.amount) : 0
                                        )
                                      ) +
                                        sumArray(
                                          _.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                                            is_admin: true,
                                            is_main: true,
                                            mobile: null,
                                          })[0]?.transactions.map((i) =>
                                            i?.type === 'WITHDRAWAL' ? parseFloat(i?.amount) : 0
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span>- Caisse principale</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            aggregateAgenciesWallets(_.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                                              is_admin: false,
                                              is_main: true,
                                              mobile: null,
                                            }))[0]?.transactions.map((i) =>
                                              i?.type === 'WITHDRAWAL' ? parseFloat(i?.amount) : 0
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td></td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span>- Coffre</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            aggregateAgenciesWallets(_.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                                              is_admin: true,
                                              is_main: true,
                                              mobile: null,
                                            }))[0]?.transactions.map((i) =>
                                              i?.type === 'WITHDRAWAL' ? parseFloat(i?.amount) : 0
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td></td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className=" bg-primary-2">
                                  <td className="text-center">Marge Commerciale Nº 1</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        getTodayObj(report?.stats)?.report?.map(
                                          (i) => i.amount_products
                                        )
                                      ) +
                                        sumArray(
                                          getTodayObj(report?.stats)?.report?.map(
                                            (i) => i.amount_services
                                          )
                                        ) +
                                        sumArray(
                                          _.filter(getTodayObj(report?.stats)?.transactions ?? [], {
                                            reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                          })?.map((i) => i.amount)
                                        ) -
                                        sumArray(
                                          aggregateAgenciesWallets(_.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                                            is_admin: true,
                                            is_main: true,
                                            mobile: null,
                                          }))[0]?.transactions.map((i) =>
                                            i?.type === 'WITHDRAWAL' ? parseFloat(i?.amount) : 0
                                          )
                                        ) -
                                        sumArray(
                                          aggregateAgenciesWallets(_.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                                            is_admin: false,
                                            is_main: true,
                                            mobile: null,
                                          }))[0]?.transactions.map((i) =>
                                            i?.type === 'WITHDRAWAL' ? parseFloat(i?.amount) : 0
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="badge-soft-danger ">
                                  <td>elements exceptionnels</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        _.filter(
                                          getTodayObj(report?.stats)?.payment_methods ?? [],
                                          {
                                            payment_method: 'SOLDE_DISPONIBLE',
                                          }
                                        )[0]?.data.map((i) => parseFloat(i?.amount))
                                      ) +
                                        sumArray(
                                          _.filter(
                                            getTodayObj(report?.stats)?.payment_methods ?? [],
                                            {
                                              payment_method: 'CREDIT',
                                            }
                                          )[0]?.data.map((i) => parseFloat(i?.amount))
                                        ) +
                                        sumArray(
                                          _.filter(
                                            getTodayObj(report?.stats)?.payment_methods ?? [],
                                            {
                                              payment_method: 'OFFER',
                                            }
                                          )[0]?.data.map((i) => parseFloat(i?.amount))
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span>- Paiements en compte</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            _.filter(
                                              getTodayObj(report?.stats)?.payment_methods ?? [],
                                              {
                                                payment_method: 'SOLDE_DISPONIBLE',
                                              }
                                            )[0]?.data.map((i) => parseFloat(i?.amount))
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td></td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span>- Crédit client</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            _.filter(
                                              getTodayObj(report?.stats)?.payment_methods ?? [],
                                              {
                                                payment_method: 'CREDIT',
                                              }
                                            )[0]?.data.map((i) => parseFloat(i?.amount))
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td></td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span>- Offres/Gratuités</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            _.filter(
                                              getTodayObj(report?.stats)?.payment_methods ?? [],
                                              {
                                                payment_method: 'OFFER',
                                              }
                                            )[0]?.data.map((i) => parseFloat(i?.amount))
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td></td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className=" bg-primary-2">
                                  <td className="text-center">Marge Commerciale Nº 2</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        getTodayObj(report?.stats)?.report?.map(
                                          (i) => i.amount_products
                                        )
                                      ) +
                                        sumArray(
                                          getTodayObj(report?.stats)?.report?.map(
                                            (i) => i.amount_services
                                          )
                                        ) +
                                        sumArray(
                                          _.filter(getTodayObj(report?.stats)?.transactions ?? [], {
                                            reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                          })?.map((i) => i.amount)
                                        ) -
                                        sumArray(
                                          aggregateAgenciesWallets(_.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                                            is_admin: true,
                                            is_main: true,
                                            mobile: null,
                                          }))[0]?.transactions.map((i) =>
                                            i?.type === 'WITHDRAWAL' ? parseFloat(i?.amount) : 0
                                          )
                                        ) -
                                        sumArray(
                                          aggregateAgenciesWallets(_.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                                            is_admin: false,
                                            is_main: true,
                                            mobile: null,
                                          }))[0]?.transactions.map((i) =>
                                            i?.type === 'WITHDRAWAL' ? parseFloat(i?.amount) : 0
                                          )
                                        ) -
                                        sumArray(
                                          _.filter(
                                            getTodayObj(report?.stats)?.payment_methods ?? [],
                                            {
                                              payment_method: 'SOLDE_DISPONIBLE',
                                            }
                                          )[0]?.data.map((i) => parseFloat(i?.amount))
                                        ) -
                                        sumArray(
                                          _.filter(
                                            getTodayObj(report?.stats)?.payment_methods ?? [],
                                            {
                                              payment_method: 'CREDIT',
                                            }
                                          )[0]?.data.map((i) => parseFloat(i?.amount))
                                        ) -
                                        sumArray(
                                          _.filter(
                                            getTodayObj(report?.stats)?.payment_methods ?? [],
                                            {
                                              payment_method: 'OFFER',
                                            }
                                          )[0]?.data.map((i) => parseFloat(i?.amount))
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="badge-soft-danger ">
                                  <td>Charges du personnel</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        _.filter(getTodayObj(report?.stats)?.transactions ?? [], {
                                          reason: 'SALAIRES',
                                        })?.map((i) => i.amount)
                                      )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span>- Salaires nets</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            _.filter(
                                              getTodayObj(report?.stats)?.payment_methods ?? [],
                                              {
                                                payment_method: 'OFFER',
                                              }
                                            )[0]?.data.map((i) => parseFloat(i?.amount))
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td></td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className="  bg-primary-2">
                                  <td className="text-center">Résultat</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        getTodayObj(report?.stats)?.report?.map(
                                          (i) => i.amount_products
                                        )
                                      ) +
                                        sumArray(
                                          getTodayObj(report?.stats)?.report?.map(
                                            (i) => i.amount_services
                                          )
                                        ) +
                                        sumArray(
                                          _.filter(getTodayObj(report?.stats)?.transactions ?? [], {
                                            reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                          })?.map((i) => i.amount)
                                        ) -
                                        sumArray(
                                          aggregateAgenciesWallets(_.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                                            is_admin: true,
                                            is_main: true,
                                            mobile: null,
                                          }))[0]?.transactions.map((i) =>
                                            i?.type === 'WITHDRAWAL' ? parseFloat(i?.amount) : 0
                                          )
                                        ) -
                                        sumArray(
                                          aggregateAgenciesWallets(_.filter(getTodayObj(report?.stats)?.caisses ?? [], {
                                            is_admin: false,
                                            is_main: true,
                                            mobile: null,
                                          }))[0]?.transactions.map((i) =>
                                            i?.type === 'WITHDRAWAL' ? parseFloat(i?.amount) : 0
                                          )
                                        ) -
                                        sumArray(
                                          _.filter(
                                            getTodayObj(report?.stats)?.payment_methods ?? [],
                                            {
                                              payment_method: 'SOLDE_DISPONIBLE',
                                            }
                                          )[0]?.data.map((i) => parseFloat(i?.amount))
                                        ) -
                                        sumArray(
                                          _.filter(
                                            getTodayObj(report?.stats)?.payment_methods ?? [],
                                            {
                                              payment_method: 'CREDIT',
                                            }
                                          )[0]?.data.map((i) => parseFloat(i?.amount))
                                        ) -
                                        sumArray(
                                          _.filter(
                                            getTodayObj(report?.stats)?.payment_methods ?? [],
                                            {
                                              payment_method: 'OFFER',
                                            }
                                          )[0]?.data.map((i) => parseFloat(i?.amount))
                                        ) -
                                        sumArray(
                                          _.filter(getTodayObj(report?.stats)?.transactions ?? [], {
                                            reason: 'SALAIRES',
                                          })?.map((i) => i.amount)
                                        )
                                    )}
                                  </td>
                                  <td className="text-success text-center"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="bg-light p-3 mb-3">
                          <p className="text-uppercase mb-0 text-center">Mois en cours</p>
                        </div>
                        <div className="border rounded-11 p-2">
                          <div className="border rounded-11 border-primary">
                            <table className="table text-uppercase text-left">
                              <thead className="bg-light">
                                <tr>
                                  <th>Libelles</th>
                                  <th>Valeur</th>
                                  <th style={{ width: '30px' }}>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="badge-soft-success">
                                  <td>Chiffre d'affaires sur les Prestations</td>
                                  <td>
                                    <span>
                                      {formatNumber(
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(i?.report?.map((i) => i.amount_services))
                                          )
                                        )
                                      )}
                                    </span>
                                  </td>
                                  <td className="text-success text-center">+</td>
                                </tr>
                                {/* Details des prestations */}
                                {report?.stats[0]?.report?.map((i, index) =>
                                  (i?.category?.type === 'SERVICE' && String(i?.category?.name)?.toUpperCase() === 'COIFFURES') ? (
                                    <tr className=" small" key={'prsta-' + index}>
                                      <td>
                                      <span className="d-flex">
                                          <span>- {i?.category?.name}</span>
                                          <span className="ms-auto border border-primary px-2">
                                            {formatNumber(
                                              sumArray(
                                                report?.stats.map((j) =>
                                                  sumArray(
                                                    j?.report?.map((k) =>
                                                      k.category.id === i?.category?.id &&
                                                      i?.category?.type === 'SERVICE'
                                                        ? parseFloat(k.amount_services)
                                                        : 0
                                                    )
                                                  )
                                                )
                                              )
                                            )}
                                          </span>
                                        </span>
                                      </td>
                                      <td>
                                      </td>
                                      <td className="text-success text-center"></td>
                                    </tr>
                                  ) : null
                                )}
                                {report?.stats[0]?.report?.map((i, index) =>
                                  (i?.category?.type === 'SERVICE' && String(i?.category?.name)?.toUpperCase() != 'COIFFURES') ? (
                                    <tr className=" small" key={'prsta-' + index}>
                                      <td>
                                      <span className="d-flex">
                                          <span>- {i?.category?.name}</span>
                                          <span className="ms-auto border border-primary px-2">
                                            {formatNumber(
                                              sumArray(
                                                report?.stats.map((j) =>
                                                  sumArray(
                                                    j?.report?.map((k) =>
                                                      k.category.id === i?.category?.id &&
                                                      i?.category?.type === 'SERVICE'
                                                        ? parseFloat(k.amount_services)
                                                        : 0
                                                    )
                                                  )
                                                )
                                              )
                                            )}
                                          </span>
                                        </span>
                                      </td>
                                      <td>
                                      </td>
                                      <td className="text-success text-center"></td>
                                    </tr>
                                  ) : null
                                )}
                                <tr className="badge-soft-success">
                                  <td>Chiffre d'affaires sur les Produits</td>
                                  <td>
                                    <span>
                                      {formatNumber(
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(i?.report?.map((i) => i.amount_products))
                                          )
                                        )
                                      )}
                                    </span>
                                  </td>
                                  <td className="text-success text-center">+</td>
                                </tr>
                                <tr className="bg-primary-2">
                                  <td>Chiffre d'affaires - Total Nº1</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((i) =>
                                          sumArray(i?.report?.map((i) => i.amount_services))
                                        )
                                      ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(i?.report?.map((i) => i.amount_products))
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-success text-center">+</td>
                                </tr>
                                <tr className="badge-soft-success">
                                  <td>Total des soldes en compte</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((i) =>
                                          i?.soldes_clients
                                        
                                        )
                                      )
                                    )}
                                  </td>
                                  <td className="text-success text-center">+</td>
                                </tr>
                                <tr className="bg-primary-2">
                                  <td>Chiffre d'affaires - Total Nº2</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((i) =>
                                          sumArray(i?.report?.map((i) => i.amount_services))
                                        )
                                      ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(i?.report?.map((i) => i.amount_products))
                                          )
                                        ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              _.filter(i?.transactions ?? [], {
                                                reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                              })?.map((j) => j.amount)
                                            )
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className="badge-soft-danger ">
                                  <td>Charges Globales</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((i) =>
                                          sumArray(
                                            aggregateAgenciesWallets(_.filter(i?.caisses ?? [], {
                                              is_admin: false,
                                              is_main: true,
                                              mobile: null,
                                            }))[0]?.transactions.map((j) =>
                                              j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                            )
                                          )
                                        )
                                      ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              aggregateAgenciesWallets(_.filter(i?.caisses ?? [], {
                                                is_admin: true,
                                                is_main: true,
                                                mobile: null,
                                              }))[0]?.transactions.map((j) =>
                                                j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                              )
                                            )
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span className="">- Caisse principale</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            report?.stats.map((i) =>
                                              sumArray(
                                                aggregateAgenciesWallets(_.filter(i?.caisses ?? [], {
                                                  is_admin: false,
                                                  is_main: true,
                                                  mobile: null,
                                                }))[0]?.transactions.map((j) =>
                                                  j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                                )
                                              )
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                  </td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span className="">- Coffre</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            report?.stats.map((i) =>
                                              sumArray(
                                                aggregateAgenciesWallets(_.filter(i?.caisses ?? [], {
                                                  is_admin: true,
                                                  is_main: true,
                                                  mobile: null,
                                                }))[0]?.transactions.map((j) =>
                                                  j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                                )
                                              )
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                  </td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className="bg-primary-2">
                                  <td>Marge Commerciale Nº1</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((i) =>
                                          sumArray(i?.report?.map((i) => i.amount_services))
                                        )
                                      ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(i?.report?.map((i) => i.amount_products))
                                          )
                                        ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              _.filter(i?.transactions ?? [], {
                                                reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                              })?.map((j) => j.amount)
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              aggregateAgenciesWallets(_.filter(i?.caisses ?? [], {
                                                is_admin: false,
                                                is_main: true,
                                                mobile: null,
                                              }))[0]?.transactions.map((j) =>
                                                j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                              )
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              aggregateAgenciesWallets(_.filter(i?.caisses ?? [], {
                                                is_admin: true,
                                                is_main: true,
                                                mobile: null,
                                              }))[0]?.transactions.map((j) =>
                                                j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                              )
                                            )
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="badge-soft-danger ">
                                  <td>Elements exceptionnels</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((j) =>
                                          sumArray(
                                            _.filter(j?.payment_methods ?? [], {
                                              payment_method: 'SOLDE_DISPONIBLE',
                                            })[0]?.data.map((i) => parseFloat(i?.amount))
                                          )
                                        )
                                      ) +
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'CREDIT',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        ) +
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'OFFER',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span className="">- Paiements en compte</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            report?.stats.map((j) =>
                                              sumArray(
                                                _.filter(j?.payment_methods ?? [], {
                                                  payment_method: 'SOLDE_DISPONIBLE',
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              )
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                  </td>
                                  <td className="text-danger text-center small"></td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span className="">- Crédit client</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            report?.stats.map((j) =>
                                              sumArray(
                                                _.filter(j?.payment_methods ?? [], {
                                                  payment_method: 'CREDIT',
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              )
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                  </td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span className="">- Offres/Gratuités</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            report?.stats.map((j) =>
                                              sumArray(
                                                _.filter(j?.payment_methods ?? [], {
                                                  payment_method: 'OFFER',
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              )
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                  </td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className="bg-primary-2">
                                  <td>Marge Commerciale Nº2</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((i) =>
                                          sumArray(i?.report?.map((i) => i.amount_services))
                                        )
                                      ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(i?.report?.map((i) => i.amount_products))
                                          )
                                        ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              _.filter(i?.transactions ?? [], {
                                                reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                              })?.map((j) => j.amount)
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              aggregateAgenciesWallets(_.filter(i?.caisses ?? [], {
                                                is_admin: false,
                                                is_main: true,
                                                mobile: null,
                                              }))[0]?.transactions.map((j) =>
                                                j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                              )
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              aggregateAgenciesWallets(_.filter(i?.caisses ?? [], {
                                                is_admin: true,
                                                is_main: true,
                                                mobile: null,
                                              }))[0]?.transactions.map((j) =>
                                                j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                              )
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'SOLDE_DISPONIBLE',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'CREDIT',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'OFFER',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="badge-soft-danger ">
                                  <td>Charges du personnel</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((j) =>
                                          sumArray(
                                            _.filter(j?.transactions ?? [], {
                                              reason: 'SALAIRES',
                                            })?.map((i) => i.amount)
                                          )
                                        )
                                      )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span className="">- Salaires nets</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            report?.stats.map((j) =>
                                              sumArray(
                                                _.filter(j?.transactions ?? [], {
                                                  reason: 'SALAIRES',
                                                })?.map((i) => i.amount)
                                              )
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                  </td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className=" bg-primary-2">
                                  <td>Résultat</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((i) =>
                                          sumArray(i?.report?.map((i) => i.amount_services))
                                        )
                                      ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(i?.report?.map((i) => i.amount_products))
                                          )
                                        ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              _.filter(i?.transactions ?? [], {
                                                reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                              })?.map((j) => j.amount)
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              aggregateAgenciesWallets(_.filter(i?.caisses ?? [], {
                                                is_admin: false,
                                                is_main: true,
                                                mobile: null,
                                              }))[0]?.transactions.map((j) =>
                                                j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                              )
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              aggregateAgenciesWallets(_.filter(i?.caisses ?? [], {
                                                is_admin: true,
                                                is_main: true,
                                                mobile: null,
                                              }))[0]?.transactions.map((j) =>
                                                j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                              )
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'SOLDE_DISPONIBLE',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'CREDIT',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'OFFER',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.transactions ?? [], {
                                                reason: 'SALAIRES',
                                              })?.map((i) => i.amount)
                                            )
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-success text-center"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                    </div>
                  </div>
                  {
                    // <DailySummary dates={dates} employee={report.agenncy} />
                  }
                  {/* <div className="bg-light p-3 mb-3">
                    <p className="text-uppercase mb-0 text-center">Autres</p>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {loadingCa && <LoaderPage label="Generation du rapport en cours..." />}
    </div>
  );
};

export default GlobalReport;
